@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?c6gxg5');
  src:  url('fonts/icomoon.eot?c6gxg5#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?c6gxg5') format('truetype'),
    url('fonts/icomoon.woff?c6gxg5') format('woff'),
    url('fonts/icomoon.svg?c6gxg5#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Remove:before {
  content: "\e90f";
}
.icon-CSV:before {
  content: "\e910";
}
.icon-data-icon:before {
  content: "\e911";
}
.icon-Email-reminder:before {
  content: "\e912";
}
.icon-bin-1:before {
  content: "\e90f";
}
.icon-search:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-list:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-inspections:before {
  content: "\e904";
}
.icon-Share:before {
  content: "\e905";
}
.icon-communities:before {
  content: "\e906";
}
.icon-warning-alert:before {
  content: "\e907";
}
.icon-file:before {
  content: "\e908";
}
.icon-dashboard:before {
  content: "\e909";
}
.icon-add:before {
  content: "\e90a";
}
.icon-warning:before {
  content: "\e90b";
}
.icon-grid:before {
  content: "\e90c";
}
.icon-edit:before {
  content: "\e90d";
}
.icon-clients:before {
  content: "\e90e";
}

// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
      -o-transform: $transforms;
     -ms-transform: $transforms;
 -webkit-transform: $transforms;
       transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
@include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
@include transform(translate($x, $y));
}
@mixin translateX ($x) {
    @include transform(translateX($x));
    }
    @mixin translateY ($y) {
        @include transform(translateY($y));
    }
// skew
@mixin skew ($x, $y) {
@include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
 moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

// placeholder 
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
  }
  
  @include placeholder {
      font-style:italic;
      color: white;
      font-weight:100;
  }
/*login  css */
::-webkit-input-placeholder {
  font-style: italic;
  color: white;
  font-weight: 100; }

:-moz-placeholder {
  font-style: italic;
  color: white;
  font-weight: 100; }

::-moz-placeholder {
  font-style: italic;
  color: white;
  font-weight: 100; }

:-ms-input-placeholder {
  font-style: italic;
  color: white;
  font-weight: 100; }

/*global styles*/
.light-background {
  background: #e9e9ec; }

.loading-wrapper {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.form-width {
  width: 58% !important; }

.driver-loder {
  text-align: center;
  width: 64px;
  margin: 0 auto; }

.pagination-custom {
  margin: 0px;
  padding: 0px;
  text-align: center;
  margin-top: 20px; }
  .pagination-custom li {
    display: inline-block;
    background-color: #ccc;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer; }
    .pagination-custom li a {
      padding: 4px 12px;
      display: block;
      color: #000; }
    .pagination-custom li a:focus {
      outline: none; }
  .pagination-custom li:first-child {
    background-color: transparent;
    margin: 5px;
    border-radius: 0px; }
  .pagination-custom li:last-child {
    background-color: transparent;
    margin: 5px;
    border-radius: 0px; }
  .pagination-custom li.selected {
    background: #0d00fe; }
    .pagination-custom li.selected a {
      color: #fff; }
    .pagination-custom li.selected a:focus {
      outline: none; }
  .pagination-custom li.disabled {
    cursor: not-allowed; }

.share-floorplan {
  font-size: 35px;
  color: #365f80; }

.list-section {
  height: 25rem;
  overflow: auto; }

.list-item {
  cursor: pointer; }

.list-item span {
  font-size: 12px;
  color: #1a4161;
  font-weight: 600; }

.form-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer; }

.form-checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  padding-top: 2px;
  display: flex;
  align-items: center;
  color: #1a4161;
  font-weight: 600; }

.form-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #1a4161;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 2px; }

.form-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 5px;
  height: 9px;
  border: solid #1a4161;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.header {
  background-color: #365f80;
  padding: 10px 40px; }
  .header ul {
    margin-bottom: 0;
    color: #fff; }
    .header ul .filter-select {
      border: none; }

svg g text {
  fill: #ffffff; }

.search-wrapper {
  position: relative; }
  .search-wrapper::before {
    font-family: "icomoon";
    content: '\E900';
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #ffffff; }
  .search-wrapper .search-box {
    position: relative;
    border-radius: 25px;
    outline: 0;
    background: none;
    border-width: 1px;
    border: 1px solid #fff;
    font-size: 12px;
    padding: 2px 5px 2px 30px;
    color: #fff;
    display: block; }
  .search-wrapper ::-webkit-input-placeholder {
    color: #ffffff;
    font-style: normal;
    font-weight: 400; }
  .search-wrapper :-moz-placeholder {
    color: #ffffff;
    font-style: normal;
    font-weight: 400; }
  .search-wrapper ::-moz-placeholder {
    color: #ffffff;
    font-style: normal;
    font-weight: 400; }
  .search-wrapper :-ms-input-placeholder {
    color: #ffffff;
    font-style: normal;
    font-weight: 400; }

.listing-wrapper {
  color: #1a4161; }
  .listing-wrapper h2 {
    font-size: 16px;
    font-weight: 700; }
  .listing-wrapper span {
    font-size: 13px;
    color: #1a4161;
    font-weight: 600; }
    .listing-wrapper span i {
      font-size: 16px;
      font-weight: 500; }
  .listing-wrapper ul li {
    color: #315471;
    font-size: 13px;
    font-weight: 500; }
    .listing-wrapper ul li span {
      font-weight: 700; }
  .listing-wrapper .review-detail-list-wrapper label {
    margin-bottom: 0; }
    .listing-wrapper .review-detail-list-wrapper label span {
      font-weight: 700; }

.cursor-pointer {
  cursor: pointer; }

.border-dark {
  border: 1.5px solid #365f80 !important; }

.button-primary.dropdown-toggle::after {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px; }

.button-secondary {
  font-family: 'Raleway', sans-serif;
  border-radius: 25px;
  background-color: #ffffff;
  color: #365f80;
  padding: 2px 30px;
  font-size: 12px;
  min-width: 130px;
  border: 1px solid transparent;
  cursor: pointer;
  font-weight: 600; }

.button-primary {
  font-family: 'Raleway', sans-serif;
  border-radius: 25px;
  background-color: #365f80;
  color: #ffffff;
  padding: 2px 30px;
  font-size: 12px;
  min-width: 130px;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer; }

.button-primary:hover,
.button-primary.btn-secondary:active,
.button-primary:focus,
.button-primary.btn-secondary:focus {
  color: #ffffff;
  background-color: #1a4161;
  box-shadow: none; }

.selectbox {
  border-radius: 25px;
  border: none;
  padding: 5px 11px 5px 28px;
  font-size: 15px;
  text-align: center !important;
  background: #fff; }

.login {
  font-family: 'Open Sans', sans-serif;
  background: #1a4161;
  height: 100vh; }
  .login .login-form {
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .login .login-form input.form-control {
      height: 28px;
      font-size: 14px;
      border-color: #d2d2d2;
      color: #ffffff;
      background: transparent; }
      .login .login-form input.form-control:focus {
        outline: none;
        box-shadow: none; }
    .login .login-form label {
      font-size: 12px;
      padding-left: 20px;
      margin-bottom: 5px; }
    .login .login-form label,
    .login .login-form a {
      color: #ffffff; }
    .login .login-form small {
      padding-left: 20px;
      font-size: 11px; }
    .login .login-form img {
      width: 100%; }
    .login .login-form .btn-login {
      display: block;
      border-radius: 118px;
      padding: 0px 32px 3px;
      font-size: 16px;
      text-align: center;
      background: #ffffff;
      border: none;
      color: #1a4161;
      margin-top: 40px; }
    .login .login-form a.external-link {
      margin-top: 40px;
      font-size: 12px; }

/*sidenav styles here */
.left-side-bar {
  font-family: 'Open Sans', sans-serif;
  width: 300px;
  height: 100vh;
  background: #1a4161;
  position: fixed;
  left: 0;
  top: 0; }
  .left-side-bar ul {
    height: 100vh; }
    .left-side-bar ul li a {
      text-decoration: none;
      display: block;
      font-size: 18px;
      padding-left: 90px;
      color: #ffffff;
      position: relative; }
      .left-side-bar ul li a:hover {
        background: #365f80; }
      .left-side-bar ul li a .navigation-icon {
        position: absolute;
        left: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 40px; }
    .left-side-bar ul li .active {
      background: #365f80; }
      .left-side-bar ul li .active::after {
        content: "";
        position: absolute;
        right: -10px;
        width: 30px;
        height: 30px;
        background-color: #365f80;
        top: 14px;
        border: 0.25rem solid #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        z-index: -1; }

.switch-content {
  width: calc(100% - 300px);
  padding: 0px; }

.dashboard {
  padding-bottom: 20px;
  min-height: 100vh; }
  .dashboard .chart-wrapper {
    background-color: #365f80;
    padding: 50px; }
    .dashboard .chart-wrapper select.selectbox {
      padding: 5px 11px 5px 28px;
      font-size: 15px;
      text-align: center !important;
      background: #ffffff; }
    .dashboard .chart-wrapper .float-right.d-flex {
      padding-right: 95px; }
    .dashboard .chart-wrapper h2 {
      font-size: 24px;
      font-weight: 600;
      color: #fff; }
    .dashboard .chart-wrapper .recharts-wrapper {
      width: 100% !important; }
      .dashboard .chart-wrapper .recharts-wrapper svg {
        overflow: visible;
        width: 100%; }
      .dashboard .chart-wrapper .recharts-wrapper .recharts-legend-wrapper {
        width: 100% !important; }
  .dashboard .count {
    background-color: #f2f2f2; }
    .dashboard .count h2 {
      font-size: 100px;
      color: #1a4161;
      font-weight: 300;
      margin-bottom: 0;
      line-height: 100px; }
    .dashboard .count p {
      color: #365f80;
      font-weight: 600;
      margin-bottom: 0; }
  .dashboard .job-listing img {
    max-width: 100%;
    height: 140px; }
  .dashboard .job-listing h2 {
    font-size: 18px;
    border-bottom: 1px solid #365f80;
    color: #1a4161;
    padding-bottom: 10px;
    font-weight: 600; }
  .dashboard .job-listing .job-info {
    font-family: 'Raleway', sans-serif; }
    .dashboard .job-listing .job-info .job-info-head h3 {
      font-size: 13px;
      margin: 0;
      font-weight: 700;
      color: #1a4161; }
    .dashboard .job-listing .job-info .job-info-head p {
      font-size: 12px;
      color: #365f80;
      font-weight: 600;
      margin-bottom: 10px; }
    .dashboard .job-listing .job-info .job-info-content {
      line-height: 15px; }
      .dashboard .job-listing .job-info .job-info-content p {
        margin: 0;
        font-size: 11px;
        color: #365f80;
        font-family: 'Raleway', sans-serif;
        font-weight: 600; }
        .dashboard .job-listing .job-info .job-info-content p span {
          color: #365f80; }

.inspections {
  padding-bottom: 20px; }
  .inspections .date-picker-wrapper .react-datepicker__input-container input {
    border-radius: 25px;
    border: none;
    padding: 2px 15px; }
  .inspections .inspection-header {
    background-color: #ffffff;
    padding: 10px 40px; }
    .inspections .inspection-header .inspection-filter-wrapper {
      cursor: pointer;
      float: left;
      background-color: #365f80;
      border-radius: 25px;
      font-size: 13px;
      color: #ffffff;
      padding: 2px 2px; }
      .inspections .inspection-header .inspection-filter-wrapper a.btn-toggle {
        border-radius: 25px;
        padding: 2px 45px 2px 45px;
        display: inline-block;
        color: #ffffff; }
      .inspections .inspection-header .inspection-filter-wrapper a.btn-toggle.active {
        color: #1a4161;
        background-color: #ffffff; }
    .inspections .inspection-header .inspection-btn-wrapper {
      float: right; }
      .inspections .inspection-header .inspection-btn-wrapper ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 0; }
        .inspections .inspection-header .inspection-btn-wrapper ul li {
          display: flex;
          align-items: center; }
          .inspections .inspection-header .inspection-btn-wrapper ul li .icon-font-size {
            font-size: 20px; }
          .inspections .inspection-header .inspection-btn-wrapper ul li .icon-font-size.active {
            background-color: #365f80;
            color: #ffffff; }
  .inspections .property-wrapper {
    font-family: 'Raleway', sans-serif; }
    .inspections .property-wrapper ul {
      list-style-type: none;
      padding: 10px 30px; }
      .inspections .property-wrapper ul li .property-card {
        background: #ffffff; }
        .inspections .property-wrapper ul li .property-card .proderty-details .property-head h2 {
          color: #1a4161;
          font-size: 15px;
          font-weight: 700; }
        .inspections .property-wrapper ul li .property-card .proderty-details .property-head p {
          font-size: 12px;
          color: #315471;
          margin-bottom: 10px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden; }
        .inspections .property-wrapper ul li .property-card .property-content span {
          font-size: 12px;
          color: #365f80;
          display: block;
          float: left; }
        .inspections .property-wrapper ul li .property-card .property-content span.title {
          font-weight: 600;
          width: 100px;
          text-align: right;
          margin-right: 15px;
          color: #365f80; }
        .inspections .property-wrapper ul li .property-card .property-content span.title-description {
          width: calc(100% - 115px);
          color: #315471;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .inspections .property-wrapper ul li .property-card .property-detail-card {
          border-bottom: 1px solid #1a4161;
          padding-bottom: 20px; }
          .inspections .property-wrapper ul li .property-card .property-detail-card .detailed-image-wrapper {
            position: relative;
            height: 325px;
            background-color: #fafafa;
            text-align: center; }
            .inspections .property-wrapper ul li .property-card .property-detail-card .detailed-image-wrapper img {
              height: 100%;
              width: 100%; }
            .inspections .property-wrapper ul li .property-card .property-detail-card .detailed-image-wrapper i {
              font-size: 25px;
              color: #1a4161; }
          .inspections .property-wrapper ul li .property-card .property-detail-card .property-detail-header h2 {
            color: #1a4161;
            font-size: 18px;
            font-weight: 700; }
          .inspections .property-wrapper ul li .property-card .property-detail-card .property-detail-header span {
            color: #1a4161; }
            .inspections .property-wrapper ul li .property-card .property-detail-card .property-detail-header span i {
              font-size: 16px; }
          .inspections .property-wrapper ul li .property-card .property-detail-card .property-details-description {
            line-height: 18px; }
            .inspections .property-wrapper ul li .property-card .property-detail-card .property-details-description span.detail-title {
              font-weight: 700;
              color: #1a4161; }
            .inspections .property-wrapper ul li .property-card .property-detail-card .property-details-description span.details-description {
              color: #315471;
              font-weight: 500;
              font-size: 14px; }
        .inspections .property-wrapper ul li .property-card .property-maps-location {
          border-bottom: 1px solid #1a4161; }
          .inspections .property-wrapper ul li .property-card .property-maps-location .map-listing ul li {
            float: left;
            width: calc(20% - 6px);
            padding: 10px 5px 0px;
            height: 100px;
            cursor: pointer;
            position: relative;
            background-color: #fafafa;
            margin: 0px 3px; }
            .inspections .property-wrapper ul li .property-card .property-maps-location .map-listing ul li img {
              height: 100%;
              display: block;
              text-align: center;
              width: 100%; }
            .inspections .property-wrapper ul li .property-card .property-maps-location .map-listing ul li .cross-btn {
              position: absolute;
              top: 0;
              right: 0;
              padding: 3px 3px 0px 3px;
              background-color: #365f80;
              color: #ffffff;
              border-radius: 2px; }
              .inspections .property-wrapper ul li .property-card .property-maps-location .map-listing ul li .cross-btn i {
                font-size: 16px; }
        .inspections .property-wrapper ul li .property-card .property-review-detail ul li {
          padding: 2px 0; }
  .inspections .property-wrapper.property-grid ul li {
    width: 33.3333%;
    float: left;
    padding: 10px; }
    .inspections .property-wrapper.property-grid ul li .property-card {
      padding: 15px; }
      .inspections .property-wrapper.property-grid ul li .property-card .property-picture {
        margin-bottom: 15px;
        height: 225px;
        overflow: hidden; }
        .inspections .property-wrapper.property-grid ul li .property-card .property-picture img {
          width: 100%;
          height: 100%; }
      .inspections .property-wrapper.property-grid ul li .property-card .proderty-details .property-head {
        border-bottom: 1px solid #365f80;
        margin-bottom: 10px; }
      .inspections .property-wrapper.property-grid ul li .property-card .proderty-details .property-content .property-listing span.title-description.hide-on-list {
        text-decoration: underline;
        font-weight: 700; }
  .inspections .property-wrapper.property-list ul li {
    padding: 5px 10px;
    width: 100%; }
    .inspections .property-wrapper.property-list ul li .property-card {
      padding: 10px;
      border-radius: 4px; }
      .inspections .property-wrapper.property-list ul li .property-card .property-picture {
        float: left;
        width: 100px;
        margin-bottom: 0;
        padding-right: 10px; }
      .inspections .property-wrapper.property-list ul li .property-card .proderty-details {
        display: flex;
        flex-direction: row;
        width: calc(100% - 100px);
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-top: 5px; }
        .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-head {
          float: left;
          width: 240px; }
          .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-head h2 {
            font-size: 13px; }
          .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-head p {
            margin-bottom: 0;
            font-size: 11px; }
        .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-content {
          width: calc(100% - 240px); }
          .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-content .property-listing {
            float: left;
            width: 22%; }
            .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-content .property-listing span.title {
              text-align: left;
              display: block;
              width: 100%;
              margin-right: 0;
              font-size: 11px; }
            .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-content .property-listing span.title.message-icon {
              margin-top: 10px; }
            .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-content .property-listing span.title-description {
              width: 100%;
              font-size: 10px; }
            .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-content .property-listing span.title-description.hide-on-list {
              display: none;
              text-decoration: underline;
              font-weight: 700; }
          .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-content :last-child {
            width: 12%; }
          .inspections .property-wrapper.property-list ul li .property-card .proderty-details .property-content .property-listing.mb-2 {
            margin-bottom: 0 !important; }
  .inspections .add-new-job {
    padding: 20px; }
    .inspections .add-new-job .add-new-job-inner {
      background-color: #ffffff; }

.manage-data {
  min-height: 100vh;
  padding: 20px 30px; }

.panel {
  font-family: 'Raleway', sans-serif;
  margin-bottom: 40px;
  border-radius: 4px;
  overflow: hidden; }
  .panel .panel-header {
    padding: 10px 15px; }
  .panel .panel-header {
    background-color: #365f80; }
    .panel .panel-header ul li {
      line-height: 100%;
      color: #ffffff; }
      .panel .panel-header ul li h2 {
        font-weight: 700;
        margin: 0;
        font-size: 16px;
        text-transform: capitalize;
        letter-spacing: .5px; }
        .panel .panel-header ul li h2 span {
          font-weight: 400; }
      .panel .panel-header ul li span {
        margin-left: 5px;
        font-size: 16px; }
  .panel .panel-body {
    width: 100%;
    background-color: #ffffff; }
    .panel .panel-body table {
      width: 100%;
      font-size: 12px; }
      .panel .panel-body table thead {
        background-color: #e6f4ff;
        color: #1a4161;
        font-weight: 600;
        border-bottom: 10px solid #fff; }
      .panel .panel-body table td,
      .panel .panel-body table th {
        padding-bottom: 4px;
        padding-top: 4px; }
      .panel .panel-body table th:first-child,
      .panel .panel-body table td:first-child {
        padding-left: 15px; }
      .panel .panel-body table tbody {
        color: #365f80;
        font-weight: 500; }
        .panel .panel-body table tbody td.table-icon {
          font-size: 15px;
          text-align: right;
          padding-right: 15px; }
          .panel .panel-body table tbody td.table-icon span {
            padding-left: 5px; }
    .panel .panel-body ul {
      margin: 0;
      padding: 5px 15px 5px 15px; }
      .panel .panel-body ul li {
        color: #1a4161;
        font-weight: bold;
        margin: 10px 0; }

.clients {
  min-height: 100vh;
  padding: 20px; }
  .clients .ClientCard {
    background: #ffffff;
    padding: 15px; }
    .clients .ClientCard .cl-card-header {
      border-bottom: 1px solid #365f80; }
      .clients .ClientCard .cl-card-header ul li {
        display: inline-block;
        font-size: 13px; }
        .clients .ClientCard .cl-card-header ul li:nth-child(2) {
          position: absolute;
          right: 0; }
        .clients .ClientCard .cl-card-header ul li h6 {
          color: #1a4161;
          font-size: 18px; }
    .clients .ClientCard .cl-card-body .ClientInfo ul li {
      font-size: 13px;
      display: inline-block;
      width: 25%;
      color: #365f80; }

/*settings style here */
.settings {
  padding: 30px;
  min-height: 100vh; }
  .settings .user-crud-section {
    border-color: #365f80 !important; }
    .settings .user-crud-section ul li {
      position: relative; }
      .settings .user-crud-section ul li span {
        display: inline-block;
        font-size: 12px; }
      .settings .user-crud-section ul li .user-name {
        width: 40%; }
      .settings .user-crud-section ul li .user-email {
        width: 60%; }
      .settings .user-crud-section ul li .trash {
        position: absolute;
        width: 16px;
        height: 16px;
        padding: -3px 1px !important;
        display: inline-block;
        right: 0;
        top: 11px;
        cursor: pointer; }
        .settings .user-crud-section ul li .trash i.material-icons {
          font-size: 12px;
          border: 2px solid;
          text-align: center;
          padding: 1px 0px;
          width: 100%;
          height: 100%;
          color: #1a4161;
          font-size: 13px;
          font-weight: bold;
          font-weight: bold;
          border-radius: 2px; }
  .settings .usercount h5 {
    font-size: 18px;
    color: #1a4161; }

/*switch styling*/
.wrapper-hangle-setting .form-group {
  margin-bottom: 5px; }

.wrapper-hangle-setting .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer; }

.wrapper-hangle-setting .form-group label {
  position: relative;
  cursor: pointer;
  width: 100px; }

.wrapper-hangle-setting .form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #365f80;
  background: #365f80;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  display: inline-block;
  position: absolute;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  width: 100%;
  border-radius: 20px;
  margin-left: -2px; }

.wrapper-hangle-setting .form-group input + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  width: 50%;
  height: 100%;
  border-width: 0 2px 2px 0;
  border-radius: 20px;
  z-index: 1;
  transition: 1s all; }

.wrapper-hangle-setting .form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: initial;
  right: 3px; }

.wrapper-hangle-setting label span:nth-child(1) {
  color: #ffffff; }

.wrapper-hangle-setting label span:nth-child(2) {
  color: #1a4161;
  background-color: #ffffff;
  border-radius: 20px; }

.wrapper-hangle-setting .form-group input:checked + label span:nth-child(1) {
  background: #ffffff;
  color: #1a4161;
  border-radius: 20px; }

.wrapper-hangle-setting .form-group input:checked + label span:nth-child(2) {
  color: #ffffff !important;
  background-color: #365f80 !important; }

.wrapper-hangle-setting .form-group label span {
  position: relative;
  z-index: 2;
  text-align: center;
  top: 2px; }

.selected-width {
  width: 48px; }

.popup-wrapper {
  font-family: 'Raleway', sans-serif; }
  .popup-wrapper .modal-body {
    padding: 0; }
    .popup-wrapper .modal-body .carousel-control-prev-icon,
    .popup-wrapper .modal-body .carousel-control-next-icon {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer; }
    .popup-wrapper .modal-body .carousel-control-next-icon {
      right: -50px; }
    .popup-wrapper .modal-body .carousel-control-prev-icon {
      left: -50px; }
  .popup-wrapper .popup-header h2 {
    margin-top: 30px;
    font-size: 25px;
    color: #1a4161;
    font-weight: 700; }
  .popup-wrapper .pop-form-wrapper {
    padding-left: 15px;
    padding-right: 15px; }
    .popup-wrapper .pop-form-wrapper h5 {
      color: #1a4161;
      font-weight: 600; }
    .popup-wrapper .pop-form-wrapper .form-group {
      margin-bottom: 5px; }
      .popup-wrapper .pop-form-wrapper .form-group .form-field-label {
        margin-bottom: 5px;
        font-size: 12px;
        color: #365f80;
        font-weight: 600;
        display: block;
        height: 15px; }
      .popup-wrapper .pop-form-wrapper .form-group .form-control {
        height: 25px;
        font-size: 12px; }
      .popup-wrapper .pop-form-wrapper .form-group textarea.form-control {
        height: auto; }
      .popup-wrapper .pop-form-wrapper .form-group .input-error-text {
        color: red;
        font-size: 8px;
        height: 13px;
        display: block; }
      .popup-wrapper .pop-form-wrapper .form-group .radioWrapper {
        height: 25px;
        display: flex;
        align-items: center; }
      .popup-wrapper .pop-form-wrapper .form-group .radioLabelClass {
        padding-left: 10px;
        font-size: 12px;
        color: #365f80;
        font-weight: 600;
        margin-right: 5px; }
    .popup-wrapper .pop-form-wrapper .Add-more-field {
      margin-top: 10px;
      width: 100%;
      color: #1a4161; }
      .popup-wrapper .pop-form-wrapper .Add-more-field .add-more-title {
        font-size: 12px;
        padding-left: 10px;
        font-weight: 700; }
    .popup-wrapper .pop-form-wrapper .margin-oposite {
      margin-left: -7px;
      margin-right: -7px; }
    .popup-wrapper .pop-form-wrapper .icon-warning-alert {
      font-size: 65px;
      color: #1a4161; }
    .popup-wrapper .pop-form-wrapper h2.heading-confirmation {
      color: #1a4161;
      font-weight: 600;
      font-size: 25px; }

.associated-community {
  font-weight: 600; }
  .associated-community .associated-header {
    color: #365f80;
    padding: 10px; }

.addresses {
  padding-left: 20px;
  padding-right: 20px; }
  .addresses .addresses-accordion {
    margin-bottom: 2px; }
    .addresses .addresses-accordion .addresses-header {
      background-color: #365f80;
      color: #ffffff;
      padding: 10px; }

.builder-wrapper {
  margin-top: 2px;
  padding-left: 20px;
  padding-right: 20px; }
  .builder-wrapper .builder-accordion {
    margin-bottom: 2px; }
    .builder-wrapper .builder-accordion .builder-header {
      background-color: #365f80;
      color: #ffffff;
      padding: 10px; }

.wrapper-searchable {
  position: relative;
  z-index: 9; }
  .wrapper-searchable .ui.multiple.search.dropdown > input.search {
    margin: 2px 2px 2px; }
  .wrapper-searchable .ui.selection.dropdown {
    min-height: 10px;
    border: 1px solid #ced4da;
    min-width: 140px;
    line-height: 1px;
    padding-right: 1em; }
  .wrapper-searchable input,
  .wrapper-searchable .text {
    font-size: 12px;
    width: 100%;
    text-align: center; }
  .wrapper-searchable .ui.multiple.search.dropdown > .text {
    font-size: 12px;
    padding: 0;
    color: #ffffff;
    width: 100%;
    text-align: center; }
  .wrapper-searchable .ui.multiple.dropdown {
    padding: 2px !important;
    min-width: 120px; }
  .wrapper-searchable .ui.multiple.dropdown > .label {
    font-size: 10px;
    padding: 2px 5px; }
  .wrapper-searchable .ui.multiple.search.dropdown > .text {
    text-align: left; }
  .wrapper-searchable .ui.selection.dropdown > .delete.icon,
  .wrapper-searchable .ui.selection.dropdown > .dropdown.icon,
  .wrapper-searchable .ui.selection.dropdown > .search.icon {
    top: 4px; }
  .wrapper-searchable .ui.default.dropdown:not(.button) > .text,
  .wrapper-searchable .ui.dropdown:not(.button) > .default.text {
    color: #ffffff; }
  .wrapper-searchable .ui.dropdown .menu .selected.item,
  .wrapper-searchable .ui.dropdown.selected,
  .wrapper-searchable .ui.dropdown .menu > .item {
    color: #ffffff; }

.wrapper-searchable.nmm {
  z-index: 7;
  position: relative; }

.wrapper-searchable.transparent-select {
  background: #365f80;
  border-radius: 25px;
  color: #ffffff; }
  .wrapper-searchable.transparent-select .ui.selection.dropdown {
    background: transparent;
    border: 0px;
    color: #ffffff;
    z-index: 0 !important; }
  .wrapper-searchable.transparent-select .ui.selection.active.dropdown {
    box-shadow: none; }
  .wrapper-searchable.transparent-select .ui.selection.active.dropdown .menu {
    top: 30px;
    background-color: #365f80;
    border: 0px; }
  .wrapper-searchable.transparent-select .ui.selection.dropdown .menu > .item {
    border-top: 0px; }
  .wrapper-searchable.transparent-select .ui.active.search.dropdown input.search:focus + .text {
    color: #fff !important; }
  .wrapper-searchable.transparent-select .ui.default.dropdown:not(.button) > .text,
  .wrapper-searchable.transparent-select .ui.dropdown:not(.button) > .default.text {
    color: #ffffff;
    text-align: center;
    width: 100%; }
  .wrapper-searchable.transparent-select .ui.search.dropdown > .text {
    text-align: center;
    width: 100%; }

.ui.fluid.dropdown {
  z-index: 0 !important; }

.form-group .ui.selection.dropdown {
  padding: 11px;
  color: #365f80;
  z-index: 0 !important; }

.form-group .ui.selection.active.dropdown .menu {
  background: #1a4161;
  color: #ffffff; }

.form-group .ui.selection.dropdown .menu > .item {
  border-top: 0px; }

.form-group .ui.radioWrapper {
  margin-right: 5px; }

.select-client {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px; }

.export {
  padding: 10px;
  background: white; }

.exportHeader {
  background-color: #365f80;
  font-family: 'Raleway', sans-serif;
  border-radius: 4px;
  overflow: hidden;
  padding: 10px 15px; }

.external-dashboard header {
  background-color: #1a4161;
  padding: 20px; }
  .external-dashboard header img {
    width: 300px; }

.external-dashboard .external-chart-wrapper {
  background-color: #365f80; }
  .external-dashboard .external-chart-wrapper .external-chart-area {
    padding: 50px;
    max-width: 1000px;
    margin: 0 auto; }
    .external-dashboard .external-chart-wrapper .external-chart-area .recharts-wrapper svg {
      overflow: visible !important; }

.external-dashboard .external-wrapper {
  padding: 50px;
  max-width: 1000px;
  margin: 0 auto; }

.external-dashboard .external-search.search-wrapper .search-box {
  border: 1px solid #365f80;
  font-size: 12px;
  padding: 2px 5px 2px 30px;
  color: #365f80;
  min-width: 240px; }
  .external-dashboard .external-search.search-wrapper .search-box::before {
    color: #365f80; }

.external-dashboard ::-webkit-input-placeholder {
  color: #365f80;
  font-style: normal;
  font-weight: 400; }

.external-dashboard :-moz-placeholder {
  color: #365f80;
  font-style: normal;
  font-weight: 400; }

.external-dashboard ::-moz-placeholder {
  color: #365f80;
  font-style: normal;
  font-weight: 400; }

.external-dashboard :-ms-input-placeholder {
  color: #365f80;
  font-style: normal;
  font-weight: 400; }

.external-dashboard .external-search.search-wrapper::before {
  color: #365f80; }

.external-dashboard .external-to-login {
  position: absolute;
  top: 50%;
  right: 20px;
  font-weight: 600;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.external-dashboard h2.external-inspection-heading {
  color: #1a4161; }

.rounded-date-picker {
  border-radius: 1.2rem;
  background-color: #355f80;
  color: white;
  font-size: 12px;
  padding: 2px 15px;
  min-width: 185px;
  border: 1px solid #fff; }

.align-email {
  line-height: 25px; }

.manage-data .panel .panel-body,
.settings .panel .panel-body {
  max-height: 300px;
  overflow: auto;
  min-height: 100px; }

@media (min-width: 1200px) {
  .inspections .property-wrapper.property-grid ul li .property-card .property-picture {
    margin-bottom: 15px;
    height: 250px;
    overflow: hidden; }
    .inspections .property-wrapper.property-grid ul li .property-card .property-picture img {
      width: 100%; } }

.gm-style > input {
  position: absolute !important;
  left: 10px !important; }

.map-card {
  border: 1px solid #ced4da; }

.dropzone-area {
  border: 1.5px dashed #365f80;
  padding: 10px; }
  .dropzone-area .select-area {
    cursor: pointer; }

.dropzone-image {
  position: relative;
  width: 11.5%;
  float: left;
  text-align: center;
  height: 100px;
  vertical-align: center;
  overflow: hidden;
  margin-left: .5%;
  margin-right: .5%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2; }
  .dropzone-image img {
    max-width: 100%;
    max-height: 100px; }
  .dropzone-image .cross-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    background-color: #365f80;
    padding: 0px 3px;
    display: block; }
    .dropzone-image .cross-btn .icon-Remove {
      color: #ffffff; }

.common-save-btn .btn {
  min-width: 150px; }

.inspections .external-link.property-wrapper.property-grid ul li .property-card .property-picture {
  height: 175px; }

.add-icon-position {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  padding: 3px 5px;
  background-color: #ccc; }

.text-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px; }

.text-elipsis1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.w-30 {
  width: 33%; }

.w-20 {
  width: 16%; }

.w-10 {
  width: 13%; }

.floor-plans-popup-wrapper .carousel-image-wrapper {
  height: 500px;
  text-align: center;
  background-color: #cecece; }
  .floor-plans-popup-wrapper .carousel-image-wrapper img {
    width: 100%; }

.css-df17o1 {
  position: fixed;
  width: 100vw;
  height: 100vh; }

.css-79elbk {
  position: static; }

.radio-options input[type=radio] {
  margin-right: 5px;
  vertical-align: baseline; }

.radio-button {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px; }

.someRandomThing {
  margin-bottom: 0px;
  color: white;
  vertical-align: middle;
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: .5px; }

.medium-submit-button {
  min-width: 200px;
  padding: 5px 20px 5px 20px; }

.header .fluid > .text {
  width: 90px !important;
  overflow: hidden !important;
  display: inline-block !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  position: absolute !important;
  padding: 5px 0px 9px !important;
  top: 7px !important; }

.header .fluid > i {
  top: 2px !important; }

.topHeader .list-inline {
  display: flex;
  align-items: center; }

.fluid {
  z-index: 999999999999999999 !important; }

.layer_top {
  position: relative;
  z-index: 99; }

.layer_top1 {
  z-index: 980; }

.layer_top2 {
  z-index: 950; }

.layer_top3 {
  z-index: 999; }

.layer_top_2 {
  position: relative;
  z-index: 90; }

.layer_top_3 {
  position: relative;
  z-index: 80; }

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.28c94aea.eot);
  src:  url(/static/media/icomoon.28c94aea.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.61c952f7.ttf) format('truetype'),
    url(/static/media/icomoon.b164f6bb.woff) format('woff'),
    url(/static/media/icomoon.276a6c97.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Remove:before {
  content: "\E90F";
}
.icon-CSV:before {
  content: "\E910";
}
.icon-data-icon:before {
  content: "\E911";
}
.icon-Email-reminder:before {
  content: "\E912";
}
.icon-bin-1:before {
  content: "\E90F";
}
.icon-search:before {
  content: "\E900";
}
.icon-arrow-right:before {
  content: "\E901";
}
.icon-list:before {
  content: "\E902";
}
.icon-arrow-down:before {
  content: "\E903";
}
.icon-inspections:before {
  content: "\E904";
}
.icon-Share:before {
  content: "\E905";
}
.icon-communities:before {
  content: "\E906";
}
.icon-warning-alert:before {
  content: "\E907";
}
.icon-file:before {
  content: "\E908";
}
.icon-dashboard:before {
  content: "\E909";
}
.icon-add:before {
  content: "\E90A";
}
.icon-warning:before {
  content: "\E90B";
}
.icon-grid:before {
  content: "\E90C";
}
.icon-edit:before {
  content: "\E90D";
}
.icon-clients:before {
  content: "\E90E";
}

